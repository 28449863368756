//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { email, required } from 'vuelidate/lib/validators'
import * as api from '@/services/api'
export default {
  props: {
    placeholder: { type: String, default: 'Your email address' },
    label: { type: String, default: 'Subscribe' }
  },
  data() {
    return {
      email: ''
    }
  },
  validations: {
    email: { email, required }
  },
  methods: {
    async subscribe() {
      try {
        this.$v.$touch()
        if (this.$v.$invalid) return

        await api.subscribeNewsletter(this.email)
        this.email = ''
        this.$v.$reset()
        this.$toast.success("Thank you for subscribing! We'll keep you posted!")
      } catch (err) {
        this.$toast.error(err.response ? err.response.data.message : err)
        if (this.$sentry) {
          this.$sentry.captureException(err)
        }
      }
    }
  }
}
