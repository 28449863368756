//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import BurgerMenu from '@/components/basics/BurgerMenu';
export default {
  components: { BurgerMenu },
  props: {
    onlyFixed: { type: Boolean, default: false },
    onlySticky: { type: Boolean, default: false },
    stickyTarget: {
      type: Number,
      default: 1040
    },
    hideDownload: { type: Boolean, default: false }
  },
  data() {
    return {
      transparentBackground: true,
      stickyNav: false,
      almostStickyNav: false,
      links: [
        // { name: 'Downloads', to: '/downloads' },
        { name: 'FAQs', to: '/support/faqs' },
        { name: 'Learn', to: 'https://docs.bomist.com' },
        { name: 'Pricing', to: '/pricing' },
        { name: 'Contact', to: '/support/contact-sales' }
      ]
    };
  },
  computed: {
    currentPage() {
      return this.$store.state.appNav.currentPage;
    }
  },
  watch: {
    $route() {
      this.setCurrentPage();
    }
  },
  mounted() {
    this.setCurrentPage();
    window.addEventListener('scroll', this.handleScroll);
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    isActive(page) {
      return this.$store.state.appNav.currentPage === page;
    },
    closeBurger() {
      if (this.$refs.appBurger) {
        this.$refs.appBurger.close();
      }
    },
    setCurrentPage() {
      this.$store.commit('appNav/setCurrentPage', this.$route.path);
      this.$store.commit('dashboardNav/setCurrentPage', this.$route.path);
    },
    handleScroll(e) {
      if (['xs', 'sm'].includes(this.$mq)) {
        this.stickyNav = false;
        return;
      }

      const targetY = this.stickyTarget;
      const offsetY = 40;
      const scrollY = window.scrollY;

      if (this.stickyNav && scrollY > targetY - offsetY) {
        // avoids flickering
        return;
      }
      this.stickyNav = window.scrollY > targetY;
    }
  }
};
