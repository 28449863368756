import Vue from 'vue';
import inEU, { isInEUTimezone } from '@segment/in-eu';
import dayjs from 'dayjs';
import TheSpinner from '@/components/basics/TheSpinner';
import TheModal from '@/components/basics/TheModal';
import TheInput from '@/components/basics/TheInput';
import TheCheckbox from '@/components/basics/TheCheckbox';
import PromiseButton from '@/components/basics/PromiseButton';

const components = {
  TheSpinner,
  TheModal,
  TheInput,
  TheCheckbox,
  PromiseButton
};

Object.entries(components).forEach(([name, component]) => {
  Vue.component(name, component);
});

const internals = {
  inEU() {
    if (process.client) {
      return inEU();
    } else {
      return isInEUTimezone();
    }
  },
  formatCurrency(value, currency) {
    if (!currency && internals.inEU() === undefined) {
      return '';
    }
    currency = currency || (internals.inEU() ? 'eur' : 'usd');
    switch (currency) {
      case 'usd':
        return `$${value}`;
      case 'eur':
        return `${value}€`;
      default:
        return `${value} ${currency.toUpperCase()}`;
    }
  }
};

export default (context, inject) => {
  inject('fmd', (fmd) => {
    return {
      ...fmd,
      component: { extends: fmd.vue.component }
    };
  });

  inject('inEU', () => {
    return internals.inEU();
  });
  inject('currency', () => {
    return internals.inEU() ? 'eur' : 'usd';
  });
  inject('formatCurrency', internals.formatCurrency);

  inject('formatPrice', (amount, currency, precision = 0) => {
    const price = (amount / 100).toFixed(precision);
    return internals.formatCurrency(price, currency);
  });

  inject('formatBillingPrice', (amount, interval, currency, precision = 0) => {
    const monthlyPrice = (interval === 'month'
      ? amount / 100
      : amount / 12 / 100
    ).toFixed(precision);

    return internals.formatCurrency(monthlyPrice, currency);
  });

  inject('formatUnixDate', (date, format = 'LL') => {
    return dayjs.unix(date).format(format);
  });

  inject('formatDate', (date, format = 'LL') => {
    return dayjs(date, 'YYYY-MM-DD').format(format);
  });
  inject('formatISODate', (date, format = 'LL') => {
    return dayjs(date).format(format);
  });

  inject('capitalize', (str) => {
    if (!str) return '';
    return str.charAt(0).toUpperCase() + str.slice(1);
  });

  inject('twitterAds', (code, data = {}) => {
    // eslint-disable-next-line no-undef
    // twq('event', code, data);
  });
};
