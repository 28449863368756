var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('nav',{class:[
    {
      'navbar--fixed': _vm.onlyFixed,
      'bg-purple': !_vm.onlyFixed,
      'd-none': _vm.onlySticky && !_vm.stickyNav,
      'bg-gradient-d-blue-stop1 navbar--sticky': _vm.stickyNav,
      'position-sticky': _vm.stickyNav && !_vm.onlyFixed,
      'fixed-top': _vm.stickyNav && !_vm.onlyFixed,
      translucent: _vm.stickyNav
    }
  ]},[_c('div',{class:[
      'container d-flex justify-content-between align-items-center',
      { shrink: _vm.stickyNav && !_vm.onlyFixed }
    ]},[_c('div',{staticClass:"logo"},[_c('nuxt-link',{attrs:{"to":"/"}},[_c('img',{attrs:{"src":require("@/assets/img/bomist_logo_yellow_black_256.png"),"alt":"BOMIST logo"}})])],1),_vm._v(" "),_c('div',{staticClass:"menu"},[_c('ul',{staticClass:"d-none d-md-flex"},[_vm._l((_vm.links),function(link){return _c('li',{key:link.to},[(link.to.startsWith('/'))?_c('nuxt-link',{class:{ active: _vm.isActive(link.to) },attrs:{"to":link.to}},[_vm._v(_vm._s(link.name))]):_c('a',{attrs:{"href":link.to}},[_vm._v(_vm._s(link.name))])],1)}),_vm._v(" "),_c('li',{staticClass:"d-flex align-items-center"},[(this.$auth.loggedIn)?_c('div',[_c('nuxt-link',{staticClass:"highlight2",attrs:{"to":"/downloads"}},[_vm._v("Download")]),_vm._v(" "),_c('nuxt-link',{staticClass:"highlight",attrs:{"to":"/dashboard"}},[_vm._v("Dashboard")])],1):_c('div',[_c('nuxt-link',{staticClass:"highlight2",attrs:{"to":"/downloads"}},[_vm._v("Download")]),_vm._v(" "),_c('nuxt-link',{staticClass:"highlight2",attrs:{"to":"/sign-up"}},[_vm._v("Sign Up")]),_vm._v(" "),_c('nuxt-link',{staticClass:"highlight",attrs:{"to":"/sign-in"}},[_vm._v("Sign In")])],1)])],2),_vm._v(" "),_c('burger-menu',{ref:"appBurger",staticClass:"d-flex d-md-none app-nav-burger"},[_c('ul',[_vm._l((_vm.links),function(link){return _c('li',{key:link.to,on:{"click":function($event){return _vm.closeBurger()}}},[(link.to.startsWith('/'))?_c('nuxt-link',{class:{ active: _vm.isActive(link.to) },attrs:{"to":link.to}},[_vm._v(_vm._s(link.name))]):_c('a',{attrs:{"href":link.to}},[_vm._v(_vm._s(link.name))])],1)}),_vm._v(" "),_c('nuxt-link',{class:{ active: _vm.isActive('/downloads') },attrs:{"to":"/downloads"}},[_vm._v("Download")]),_vm._v(" "),_c('li',{on:{"click":function($event){return _vm.closeBurger()}}},[(this.$auth.loggedIn)?_c('nuxt-link',{staticClass:"highlight",attrs:{"to":"/dashboard"}},[_vm._v("Dashboard")]):_c('nuxt-link',{staticClass:"highlight",attrs:{"to":"/sign-in"}},[_vm._v("Sign-In")])],1)],2)])],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }