//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: { error: { type: Object, required: true } },
  data: () => ({
    isDev: process.env.NODE_ENV === 'development'
  }),
  mounted() {
    if (this.$sentry) {
      this.$sentry.captureMessage(`Page not found: ${this.$route.path}`)
    }
  }
}
