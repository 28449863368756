//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import AppNav from '@/components/AppNav.vue';
import AppHero from '@/components/AppHero.vue';
import AppFooter from '@/components/AppFooter.vue';

export default {
  components: {
    AppNav,
    AppHero,
    AppFooter
  },
  head() {
    return {
      // script: {
      //   src:
      //     'https://widget.senja.io/widget/c51dcc05-9a09-4f79-a651-49d4245cff64/platform.js',
      //   body: true
      //   defer: true
      // }
    };
  },
  computed: {
    showSenjaPopup() {
      return process.env.SENJA_HERO_POPUP === 'yes';
    }
  }
};
