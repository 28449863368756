import { render, staticRenderFns } from "./hero.vue?vue&type=template&id=2c2cf0c6&scoped=true&"
import script from "./hero.vue?vue&type=script&lang=js&"
export * from "./hero.vue?vue&type=script&lang=js&"
import style0 from "./hero.vue?vue&type=style&index=0&id=2c2cf0c6&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2c2cf0c6",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AppNav: require('/opt/build/repo/components/AppNav.vue').default,AppHero: require('/opt/build/repo/components/AppHero.vue').default,AppFooter: require('/opt/build/repo/components/AppFooter.vue').default})
