import { render, staticRenderFns } from "./TheModal.vue?vue&type=template&id=f28e68f6&%40keyup.esc.stop=close()&"
import script from "./TheModal.vue?vue&type=script&lang=js&"
export * from "./TheModal.vue?vue&type=script&lang=js&"
import style0 from "./TheModal.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {PromiseButton: require('/opt/build/repo/components/basics/PromiseButton.vue').default})
