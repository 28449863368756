import * as api from '@/services/api';

export const state = () => ({
  data: {}
});

export const mutations = {
  setPlans(state, plans) {
    state.data = plans;
  }
};

export const actions = {
  async fetch(ctx, { currency, fromPlan }) {
    try {
      const response = await api.plans(currency, fromPlan);
      const plans = response.data;
      ctx.commit('setPlans', plans);
    } catch (err) {
      console.error(err);
    }
  }
};
