//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    label: { type: String, default: '' },
    loading: { type: Boolean, required: true },
    size: { type: Number, default: 16 }
  }
}
