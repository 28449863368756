//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    id: { type: String, default: '' },
    value: { type: Boolean, default: false },
    faded: { type: Boolean, default: false }
  },
  methods: {
    toggle() {
      this.$emit('input', !this.value);
    }
  }
};
