//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    promise: { type: Function, required: true },
    disabled: { type: Boolean, default: false },
    debounce: { type: Number, default: 200 },
    type: { type: String, default: 'button' }
  },
  data() {
    return {
      loading: false,
      processing: false
    };
  },
  watch: {
    processing() {
      if (this.processing) {
        if (!this.loading) {
          setTimeout(() => {
            this.loading = this.processing;
          }, this.debounce);
        }
      } else {
        this.loading = false;
      }
    }
  },
  methods: {
    async handle() {
      try {
        this.processing = true;
        await this.promise();
        this.$emit('done');
      } catch (err) {
        throw err;
      } finally {
        this.processing = false;
      }
    }
  }
};
