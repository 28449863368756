//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import 'vue-slick-carousel/dist/vue-slick-carousel.css';
// optional style for arrows & dots
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css';

export default {
  name: 'ImageSlider',
  data() {
    return {
      slickOptions: {
        // slidesToShow: 3,
        pauseOnHover: false,
        autoplay: true,
        autoplaySpeed: 5000,
        arrows: false,
        dots: true,
        infinite: true,
        fade: true,
        lazyLoad: 'progressive'
      }
    };
  }
};
