/* eslint-disable */
export default () => {
  window.$crisp = []
  window.$crisp.push(['safe', true])
  window.CRISP_WEBSITE_ID = '2dff60a7-8987-44b6-8877-3b1a06ec9121'
  ;(function() {
    var d = document
    var s = d.createElement('script')

    s.src = 'https://client.crisp.chat/l.js'
    s.async = 1
    d.getElementsByTagName('head')[0].appendChild(s)
  })()
}
