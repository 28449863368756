/* eslint-disable */
export default () => {
  (function() {
    var d = document;
    var scriptUrls = [
      'https://widget.senja.io/widget/c51dcc05-9a09-4f79-a651-49d4245cff64/platform.js',
      'https://widget.senja.io/widget/68f22d11-50ff-419e-b9da-9a17dba5b816/platform.js'
    ];
    for (var scriptUrl of scriptUrls) {
      var s = d.createElement('script');
      s.src = scriptUrl;
      s.async = 1;
      d.getElementsByTagName('body')[0].appendChild(s);
    }
  })();
};
