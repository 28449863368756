import Vue from 'vue';
import * as api from '@/services/api';

const emptyInvoices = {
  data: null,
  hasMore: false,
  page: 0,
  totalPages: 0
};

export const state = () => ({
  customer: null,
  taxRates: null,
  subscriptions: null,
  paymentMethods: null,
  invoices: Object.assign({}, emptyInvoices)
});

export const mutations = {
  clear(state) {
    state.customer = null;
    state.taxRates = null;
    state.subscriptions = null;
    state.paymentMethods = null;
    Vue.set(state, 'invoices', Object.assign({}, emptyInvoices));
  },
  setCustomer(state, customer) {
    state.customer = customer;
  },
  setTaxRates(state, taxRates) {
    state.taxRates = taxRates;
  },
  setSubscriptions(state, subscriptions) {
    state.subscriptions = subscriptions;
  },
  setWorkspaces(state, workspaces) {
    Vue.set(state, 'workspaces', [...workspaces]);
  },
  setPaymentMethods(state, paymentMethods) {
    state.paymentMethods = paymentMethods;
  },
  setInvoices(state, invoices) {
    Vue.set(state, 'invoices', invoices);
  },
  setOpenInvoices(state, invoices) {
    Vue.set(state, 'openInvoices', invoices);
  },
  setInvoicesLoading(state) {
    state.invoices.data = null;
  }
};

export const actions = {
  async fetchUserData() {
    await this.$auth.fetchUser();
  },
  async fetchCustomer(ctx) {
    const response = await api.customer();
    ctx.commit('setCustomer', response.data);
    return response;
  },
  async fetchTaxRates(ctx, plan) {
    const response = await api.customerTaxRates(plan);
    ctx.commit('setTaxRates', response.data);
    return response;
  },
  async fetchSubscriptions(ctx) {
    const response = await api.subscriptions();
    const { subscriptions } = response.data;
    ctx.commit('setSubscriptions', subscriptions);
    return response;
  },
  async fetchWorkspaces(ctx) {
    const response = await api.workspaces();
    ctx.commit('setWorkspaces', response.data);
    return response;
  },
  async fetchPaymentMethods(ctx) {
    const response = await api.paymentMethods();
    ctx.commit('setPaymentMethods', response.data);
    return response;
  },
  async fetchInvoices(ctx) {
    ctx.commit('setInvoicesLoading');
    const response = await api.invoices();
    const invoices = response.data;

    ctx.commit('setInvoices', {
      data: invoices.data,
      page: 1,
      totalPages: invoices.has_more ? 2 : 1
    });
    return response;
  },
  async fetchOpenInvoices(ctx) {
    const response = await api.invoices('', '', 'open');
    const invoices = response.data;

    ctx.commit('setOpenInvoices', invoices.data);
    return response;
  },
  async fetchInvoicesNext(ctx) {
    const after = ctx.state.invoices.data[ctx.state.invoices.data.length - 1];
    const response = await api.invoices(after.id);
    const invoices = response.data;
    ctx.commit('setInvoices', {
      data: invoices.data,
      page: ctx.state.invoices.page + 1,
      totalPages: invoices.has_more
        ? ctx.state.invoices.page + 2
        : ctx.state.invoices.totalPages
    });
    return response;
  },
  async fetchInvoicesPrev(ctx) {
    const before = ctx.state.invoices.data[0];
    const response = await api.invoices('', before.id);
    const invoices = response.data;
    ctx.commit('setInvoices', {
      data: invoices.data,
      page: ctx.state.invoices.page - 1,
      totalPages: ctx.state.invoices.totalPages
    });
    return response;
  }
};
