//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import AppNav from '@/components/AppNav';
import AppFooter from '@/components/AppFooter';
import DashboardNav from '@/components/elements/DashboardNav';

export default {
  components: { AppNav, DashboardNav },
  head: {
    meta: [
      {
        name: 'robots',
        content: 'noindex,nofollow,noarchive'
      }
    ],
    script: [
      {
        src: 'https://js.stripe.com/v3/',
        // body: true,
        type: 'text/javascript',
        async: true
      }
    ]
  },
  // async asyncData({ store, route, redirect }) {
  //   try {
  //     if (!store.state.user.customer) {
  //       await store.dispatch('user/fetchCustomer')
  //     }
  //     if (!store.state.user.subscriptions) {
  //       store.dispatch('user/fetchSubscriptions')
  //     }
  //     if (!store.state.user.paymentMethods) {
  //       store.dispatch('user/fetchPaymentMethods')
  //     }
  //     // if (!this.$store.state.user.workspaces) {
  //     store.dispatch('user/fetchWorkspaces')
  //     // }
  //     store.commit('dashboardNav/setCurrentPage', route.path)
  //   } catch (err) {
  //     redirect('/sign-in')
  //   }
  //   return {}
  // }
  async mounted() {
    try {
      if (!this.$store.state.user.customer) {
        await this.$store.dispatch('user/fetchCustomer');
      }
      if (!this.$store.state.user.subscriptions) {
        this.$store.dispatch('user/fetchSubscriptions');
      }
      if (!this.$store.state.user.paymentMethods) {
        this.$store.dispatch('user/fetchPaymentMethods');
      }
      // if (!this.$store.state.user.workspaces) {
      this.$store.dispatch('user/fetchWorkspaces');
      // }
      this.$store.commit('dashboardNav/setCurrentPage', this.$route.path);
    } catch (err) {
      this.$router.push('/sign-in');
    }
  }
};
