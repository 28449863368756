//
//
//
//

export default {
  head: {
    meta: [
      {
        name: 'robots',
        content: 'noindex,nofollow,noarchive'
      }
    ]
  }
}
