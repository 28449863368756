import omitBy from 'lodash/omitBy';
import isEmpty from 'lodash/isEmpty';
import urljoin from 'url-join';

let axiosClient = null;

export function setAxiosClient(client) {
  axiosClient = client;
}

async function axiosRequest(method, url, data, opts) {
  // console.log('axiosRequest', process.env.BOMIST_API_URL, url)
  try {
    const response = await axiosClient.request({
      baseUrl: process.env.BOMIST_API_URL,
      method,
      url,
      loading: false,
      ...omitBy(
        {
          data,
          ...opts
        },
        isEmpty
      )
    });
    return response;
  } catch (err) {
    throw err;
  }
}

export function ping() {
  return axiosRequest('get', '/ping');
}

export function createUser(
  name,
  email,
  password,
  currency,
  subscribeNewsletter
) {
  return axiosRequest('post', '/user', {
    user: { name, email, password, currency },
    subscribe_newsletter: subscribeNewsletter
  });
}

export function updateUser(data) {
  return axiosRequest('put', '/user', { user: data });
}

export function resendConfirmationEmail(email) {
  return axiosRequest('post', '/user/resend-verification-email', {
    email
  });
}

export function forgotPassword(email) {
  return axiosRequest('post', '/user/forgot-password', { email });
}

export function recoverPassword(token, newPassword) {
  return axiosRequest('post', '/user/recover-password', {
    token,
    new_password: newPassword
  });
}

export function resetPassword(oldPassword, newPassword) {
  return axiosRequest('post', '/user/reset-password', {
    old_password: oldPassword,
    new_password: newPassword
  });
}

export function confirmUser(token) {
  return axiosRequest('post', '/user/confirm', { token });
}

export function changeEmail(token, newEmail) {
  return axiosRequest('post', '/user/change-email', {
    token,
    new_email: newEmail
  });
}

export function customer() {
  return axiosRequest('get', '/user/customer');
}

export function customerTaxRates(plan) {
  return axiosRequest('get', '/user/customer/tax-rates', null, {
    params: { plan }
  });
}

export function validateVAT(vatNumber) {
  return axiosRequest('get', '/user/validate-vat', null, {
    params: { vat_number: vatNumber }
  });
}

export function subscriptions() {
  return axiosRequest(
    'get',
    '/user/subscriptions/verify',
    {},
    { params: { c: 'c' } }
  );
}

export function workspaces() {
  return axiosRequest('get', '/user/workspaces');
}

export function createSubscription(plan, promoCode) {
  return axiosRequest('post', '/user/subscriptions', {
    plan,
    promo_code: promoCode
  });
}

export function changePlan(subscription, newPlan, promoCode) {
  return axiosRequest('put', '/user/subscriptions/plan', {
    subscription,
    new_plan: newPlan,
    promo_code: promoCode
  });
}

export function cancelSubscription(subscription) {
  return axiosRequest('delete', '/user/subscriptions', { subscription });
}

export function revertSubscriptionCancelation(subscription) {
  return axiosRequest('post', '/user/subscriptions/revert-cancelation', {
    subscription
  });
}

export function subscriptionPreview(newPlan, promoCode = '') {
  return axiosRequest('post', '/user/subscriptions/preview', {
    new_plan: newPlan,
    promo_code: promoCode
  });
}

export function prorationPreview(subscription, newPlan, promoCode = '') {
  return axiosRequest('post', '/user/subscriptions/proration-preview', {
    subscription,
    new_plan: newPlan,
    promo_code: promoCode
  });
}

export function paymentMethods() {
  return axiosRequest('get', '/user/payment_methods');
}

export function createCard(paymentMethodId) {
  return axiosRequest('post', '/user/payment_methods/card', {
    token: paymentMethodId
  });
}

export function removeCard() {
  return axiosRequest('delete', '/user/payment_methods/card');
}

export function invoices(
  startingAfter = '',
  endingBefore = '',
  status = '',
  limit = 12
) {
  return axiosRequest('get', '/user/invoices', null, {
    params: {
      limit,
      ...omitBy(
        {
          starting_after: startingAfter,
          ending_before: endingBefore,
          status
        },
        isEmpty
      )
    }
  });
}

export function invoicePDF(invoice) {
  return axiosRequest('get', '/user/invoices/pdf', null, {
    params: {
      id: invoice
    }
  });
}

export function plans(currency = 'eur', fromPlan = null) {
  return axiosRequest('get', '/plans', null, {
    params: omitBy({ currency, from_plan: fromPlan }, isEmpty)
  });
}

export function send(from, subject, message) {
  // mail
  return axiosRequest('post', '/send', {
    from,
    subject,
    body: message.replace(new RegExp('\\n', 'g'), '<br />')
  });
}

export function subscribeNewsletter(email) {
  return axiosRequest('post', '/newsletter/subscribe', { email });
}

export function releasesV2() {
  return axiosRequest('get', '/release/latest');
}

export function releaseNotes(name = '') {
  return axiosRequest('get', urljoin('/release/notes', name));
}

export function releasesMostRecent() {
  return axiosRequest('get', '/releases/most-recent');
}

export function releaseChangelog(version) {
  return axiosRequest('get', urljoin('/releases/changelog', version));
}

export function changeWorkspaceName(workspaceId, name) {
  return axiosRequest('put', urljoin('/user/workspaces', workspaceId), {
    name
  });
}

export function resendWorkspaceInvite(workspaceId, email) {
  return axiosRequest(
    'post',
    urljoin('/user/workspaces', workspaceId, 'members/resend-invitation'),
    {
      email
    }
  );
}

export function workspaceConfirmInvite(token) {
  return axiosRequest('post', '/user/workspaces/confirm-invite', { token });
}

export function workspaceAcceptInvite(token, account) {
  return axiosRequest('post', '/user/workspaces/accept-invitation', {
    token,
    account
  });
}

export function workspaceAddMember(workspaceId, email) {
  return axiosRequest(
    'post',
    urljoin('/user/workspaces', workspaceId, 'members'),
    {
      email
    }
  );
}

export function workspaceRemoveMember(workspaceId, user) {
  return axiosRequest(
    'delete',
    urljoin('/user/workspaces', workspaceId, 'members'),
    {
      user
    }
  );
}

export function workspaceRemoveInvitation(workspaceId, email) {
  return axiosRequest(
    'delete',
    urljoin('/user/workspaces', workspaceId, 'invitation'),
    {
      email
    }
  );
}
