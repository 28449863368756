//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    value: { type: String, default: '' },
    disabled: { type: Boolean, default: false },
    type: { type: String, required: true },
    required: { type: Boolean, default: false },
    placeholder: { type: String, required: true },
    helper: { type: String, default: '' },
    wrapperProps: { type: Object, default: () => {} },
    labelProps: { type: Object, default: () => {} },
    inputProps: { type: Object, default: () => {} },
    errorMessage: { type: String, default: '' },
    showError: { type: Boolean, default: false }
  },
  computed: {
    wrapperClass() {
      if (this.wrapperProps && this.wrapperProps.class) {
        return this.wrapperProps.class
      }
      return {}
    }
  }
}
