export default function(req, res, next) {
  const redirects = [
    {
      from: '/learn',
      to: 'https://docs.bomist.com'
    },
    {
      from: '/changelog',
      to: 'https://docs.bomist.com/changelog'
    },
    {
      from: '/changelog/beta',
      to: 'https://docs.bomist.com/changelog/beta'
    }
  ];
  const redirect = redirects.find((r) => r.from === req.url);
  if (redirect) {
    res.writeHead(301, { Location: redirect.to });
    res.end();
  } else {
    next();
  }
}
