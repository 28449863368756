//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    links: {
      type: Array,
      required: true
    },
    home: { type: Object, default: () => {} }
  }
}
