//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import FocusTrap from 'vue-focus-lock';
export default {
  components: { FocusTrap },
  props: {
    transition: { type: String, default: 'fade' },
    size: { type: String, default: '' },
    title: { type: String, default: '' },
    buttons: { type: Array, default: () => [] },
    hasCloseButton: { type: Boolean, default: true }
  },
  data() {
    return {
      isOpen: false,
      modalData: {}
    };
  },
  beforeMount() {
    window.addEventListener('keyup', this.onKeyDown);
  },
  beforeDestroy() {
    window.removeEventListener('keyup', this.onKeyDown);
  },
  mounted() {
    // console.log('props', this.props)
  },
  methods: {
    open(data) {
      // document.documentElement.style.overflow = 'hidden'
      this.$emit('before-open');
      this.modalData = data;
      // console.log('modal open', data)
      this.isOpen = true;
    },
    close() {
      // document.documentElement.style.overflow = 'auto'
      this.$emit('before-close');
      this.isOpen = false;
    },
    onClose($e) {
      const classList = Array.from($e.target.classList);
      if (
        classList.includes('modal__backdrop') ||
        classList.includes('modal__close')
      ) {
        this.close();
      }
    },
    onKeyDown($e) {
      if ($e.key === 'Escape') {
        this.isOpen = false;
      }
    },
    buttonClick(btn) {
      if (!btn.triggers || (btn.triggers && !btn.triggers.click)) {
        this.isOpen = false;
      }
    },
    onPromiseDone(btn) {
      this.isOpen = false;
    }
  }
};
