import { render, staticRenderFns } from "./learn.vue?vue&type=template&id=3f58621c&scoped=true&"
import script from "./learn.vue?vue&type=script&lang=js&"
export * from "./learn.vue?vue&type=script&lang=js&"
import style0 from "./learn.vue?vue&type=style&index=0&id=3f58621c&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3f58621c",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AppNav: require('/opt/build/repo/components/AppNav.vue').default,ContentMenu: require('/opt/build/repo/components/basics/ContentMenu.vue').default,Breadcrumbs: require('/opt/build/repo/components/basics/Breadcrumbs.vue').default,AppFooter: require('/opt/build/repo/components/AppFooter.vue').default})
