import { setAxiosClient } from '@/services/api'

export default ({ app }) => {
  setAxiosClient(app.$axios)

  app.$axios.interceptors.response.use(
    (response) => {
      return response
    },
    (err) => {
      if (err.config.url.startsWith(process.env.BOMIST_API_URL)) {
        if ([401, 403].includes(err.response.status)) {
          if (app.$auth.loggedIn) app.$auth.logout()
          app.store.commit('user/clear')
          app.router.push('/sign-in')
        }
      }
      return Promise.reject(err)
    }
  )
}
