var render = function () {
var _obj;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('transition',{attrs:{"name":_vm.transition}},[(_vm.isOpen)?_c('form',{staticClass:"modal__backdrop",attrs:{"aria-hidden":_vm.isOpen ? 'false' : 'true',"role":"dialog"},on:{"mousedown":_vm.onClose,"submit":function($event){$event.stopPropagation();$event.preventDefault();}}},[_c('focus-trap',{staticClass:"modal__wrapper",class:( _obj = {}, _obj[_vm.size] = true, _obj )},[_c('div',{staticClass:"modal__header"},[_vm._t("header",[_c('div',{staticClass:"f-between"},[_c('h3',{staticClass:"modal__title m-0 p-0"},[_vm._v(_vm._s(_vm.title))]),_vm._v(" "),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.hasCloseButton),expression:"hasCloseButton"}],staticClass:"modal__close",attrs:{"aria-label":"close"}},[_vm._v("\n              ⨉\n            ")])])],{"data":_vm.modalData})],2),_vm._v(" "),_c('div',{staticClass:"modal__body"},[_vm._t("default",null,{"data":_vm.modalData})],2),_vm._v(" "),_c('div',{staticClass:"modal__footer"},[_vm._t("footer",[(_vm.buttons.length)?_c('div',{staticClass:"d-flex flex-row-reverse align-items-center"},[_c('div',{staticClass:"d-flex justify-content-between mt-4"},_vm._l((_vm.buttons),function(btn,index){return _c('div',{key:index,staticClass:"ml-2"},[(btn.promise)?_c('promise-button',_vm._g(_vm._b({class:[
                    btn.class,
                    {
                      secondary: index !== _vm.buttons.length - 1
                    }
                  ],attrs:{"type":index === _vm.buttons.length - 1 ? 'submit' : 'button',"promise":btn.promise},on:{"done":function($event){return _vm.onPromiseDone(btn)}}},'promise-button',btn.props,false),btn.triggers),[_vm._v(_vm._s(btn.name))]):_c('button',_vm._g(_vm._b({class:{
                    secondary: index !== _vm.buttons.length - 1
                  },attrs:{"type":index === _vm.buttons.length - 1 ? 'submit' : 'button'},on:{"click":function($event){return _vm.buttonClick(btn)}}},'button',btn.props,false),btn.triggers),[_vm._v("\n                  "+_vm._s(btn.name)+"\n                ")])],1)}),0)]):_vm._e()],{"data":_vm.modalData})],2)])],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }