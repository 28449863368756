/* eslint-disable import/no-duplicates */
import Vue from 'vue';
import VTooltip from 'v-tooltip';
// import FloatingVue from 'floating-vue';
// import {
//   // Directives
//   //   VTooltip,
//   VClosePopper,
//   // Components
//   Dropdown,
//   Tooltip,
//   Menu
// } from 'floating-vue';
// import 'floating-vue/dist/style.css';

Vue.use(VTooltip);
// Vue.use(FloatingVue);

// Vue.directive('tooltip', VTooltip);
// Vue.directive('close-popper', VClosePopper);

// Vue.component('VDropdown', Dropdown);
// Vue.component('VTooltip', Tooltip);
// Vue.component('VMenu', Menu);
