//
//
//
//
//
//
//
//
//
//
//
//

import AppNav from '@/components/AppNav.vue';
import AppFooter from '@/components/AppFooter.vue';
import NeedHelpChat from '~/components/basics/NeedHelpChat.vue';
export default {
  head({ $route }) {
    const routeName =
      $route.name.charAt(0).toUpperCase() + $route.name.slice(1);
    return {
      title: `BOMIST: ${routeName}`
    };
  }
};
