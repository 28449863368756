//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import AppNav from '@/components/AppNav.vue';
import AppFooter from '@/components/AppFooter.vue';
import ContentMenu from '@/components/basics/ContentMenu';
import Breadcrumbs from '@/components/basics/Breadcrumbs';

export default {
  components: { AppNav, AppFooter, ContentMenu, Breadcrumbs },
  data() {
    return {
      links: [
        { name: 'Getting Started' },
        { name: 'Workspaces' },
        { name: 'Import & Export' },
        { name: 'Parts' },
        { name: 'Projects' },
        { name: 'Purchasing' },
        { name: 'Sales' },
        { name: 'Suppliers' },
        { name: 'Lots' },
        { name: 'Storage' },
        { name: 'Documents' },
        { name: 'Barcodes' },
        { name: 'API' },
        { name: 'History' },
        { name: 'Backups' },
        { name: 'Sharing' },
        { name: 'Shortcuts' }
      ].map((link) => {
        return { ...link, path: this.createLinkPath(link) };
      })
    };
  },
  computed: {
    // isIndex() {
    //   return this.$route.path === '/learn'
    // },
    routePath() {
      return this.$route.path.replace(/\/$/, '');
    },
    breadcrumbs() {
      return this.links.filter((link) => this.path === this.routePath);
    },
    nextLink() {
      let nextIndex = -1;
      this.links.forEach((link, i) => {
        if (link.path === this.routePath && i < this.links.length) {
          nextIndex = i + 1;
        }
      });
      if (nextIndex < 0) return null;
      return this.links[nextIndex];
    }
  },
  methods: {
    createLinkPath(link) {
      return `/learn/${link.name
        .toLowerCase()
        .replace(/&/g, 'and')
        .replace(/\s/g, '-')}`;
    },
    isActive(link) {
      return this.routePath === this.createLinkPath(link);
    }
  },
  head: {
    title: 'BOMIST: Learn'
  }
};
